import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './about.scss';
import Header from '../header';
import Footer from '../footer';
import about from '../images/about_us.png';
import leftContant from '../images/left-contant.png';

const About = () => {
  return (
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info">
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>We are Wenidi</h1>
                          <p>At Wenidi, we ignite innovation. With a blend of expertise, creativity, and commitment, we provide tailored IT solutions that drive success.</p>                            
                      </div>
                      <div className="col-sm-4">
                         <img src={about}/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container marketing">
          <div class="row featurette dataInfoCnt">
          <div class="col-md-5">
                  <img src={leftContant}/>
              </div>
              <div class="col-md-7">
              <h1>Who We Are?</h1>
              <p>We strive to build long-lasting partnerships with our clients, focusing on their success and delivering exceptional customer service, we aim to be at the forefront of the IT industry, constantly pushing the boundaries of what is possible and driving digital transformation for our clients and We envision a future where businesses can fully harness the power of technology to achieve their goals. </p>
              <p>We are dedicated to making that vision a reality through our innovative solutions and unwavering commitment to our clients.</p>
              </div>
              </div>
          </div>
          <div className="container marketing">
          <div class="row featurette">
              <div class="col-md-9 dataInfoCnt">
                <h6>Meet the Wenidi's Founder</h6>
                <p>Puja Pandey is the Esteemed Founder of Wenidi, an innovative firm specializing in IT services, consulting, and product engineering, dedicated to creating tailored solutions and cutting-edge IT products.</p>
                <p>Under the inspiring leadership of Mrs. Pandey, Wenidi has metamorphosed from a modest startup in India's bustling tech landscape into an internationally recognized beacon of digital transformation and engineering services</p>
                 <p>In today's rapidly evolving technological landscape, our mission at Wenidi is more vital than ever, to empower businesses to reach their full potential through innovative IT solutions.</p>
                <p>Since our inception in 2021, we have dedicated ourselves to providing our clients with cutting-edge strategies, tailored to their unique needs and objectives. Our team of experts, drawn from diverse backgrounds in technology and business, works hand-in-hand with our clients, guiding them through every step of their digital transformation journey.</p>
                <p>Our commitment to excellence goes beyond merely delivering top-notch IT consulting services. We believe in forging lasting relationships grounded in trust and collaboration. By understanding the nuances of your business, we craft bespoke solutions that drive efficiency, enhance security, and foster growth.</p>
                <p>The future is brimming with opportunities, and we at Wenidi are excited to explore new horizons, innovate, and continue our pursuit of excellence. Your success is our success, and we invite you to join us on this exhilarating journey.</p>
                <p>Thank you for considering Wenidi. Together, we will shape the future.</p>
              </div>
              <div class="col-md-3">
                <div className='directorMsg'>
                   <img src="/images/director_new.png" alt="Puja Pandey"/>
                   <h3>Puja Pandey</h3>
                   <p style={{fontSize:16}}>Founder & Executive Director - Wenidi Technologies Pvt Ltd. </p>
                   {/* <div className='director'><a href='https://www.linkedin.com/in/puja-pandey-b1499920a/' target='_blank'>Connect Linkedin</a></div> */}
                </div>
              </div>
              </div>
          </div>
          {/* <div className="container marketing">
          <div class="row featurette">
              <div class="col-md-9 dataInfoCnt">
                <h6>Meet the director of IT</h6>
                <p>I am delighted to bring you some exciting updates from the heart of our technological endeavors here at Wenidi. As the Director of IT, it has been a thrilling journey overseeing our efforts to enhance your digital experience.</p>
                <p>In response to the ever-evolving landscape of technology, our IT department has been diligently working on several initiatives aimed at fortifying and elevating our digital infrastructure. Our primary focus has been on ensuring robustness, reliability, and scalability, with significant investments made to upgrade our systems.</p>
                <p>In light of the increasing importance of cybersecurity, we have implemented state-of-the-art measures to safeguard your data. Our commitment to providing a secure environment remains unwavering, and we are dedicated to staying vigilant against emerging threats.</p>
                <p>To make your journey with Wenidi even more seamless, we are introducing new user-centric features and tools. Our aim is to provide you with an intuitive and efficient platform that caters to your unique needs.</p>
                <p>In embracing the power of cloud technology, we have seamlessly integrated cloud solutions into our workflow. This not only optimizes resource utilization but also fosters better communication and accessibility of data across teams.</p>
                <p>Beyond technological upgrades, our commitment extends to ongoing support and training. We want to ensure that you not only benefit from the enhancements we've made but also feel confident in utilizing the full potential of our digital ecosystem.</p>
                <p>Your feedback is crucial to us. Whether you have questions, suggestions, or thoughts to share, please don't hesitate to reach out. We value your input and are here to make your experience with Wenidi exceptional.</p>
                <p>Thank you for being an integral part of our journey. Together, we will continue to innovate and set new standards for digital excellence.</p>
              </div>
              <div class="col-md-3">
                <div className='directorMsg'>
                   <img src="/images/visnoi.png" alt="Neha Vishnoi"/>
                   <h3>Neha Vishnoi</h3>
                   <p style={{fontSize:16}}>Director of IT - Wenidi Technologies Pvt Ltd. </p> */}
                   {/* <div className='director'><a href='https://www.linkedin.com/in/puja-pandey-b1499920a/' target='_blank'>Connect Linkedin</a></div> */}
                {/* </div>
              </div>
              </div>
          </div> */}
          <Footer/>
      </div>
    );
}

export default About;