import React, {useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import facebook from '../images/facebook.png';
import twitter from '../images/twitter.png';
import linkdin from '../images/linkdin.png';
// import youtube from '../images/youtube.png';
import 'bootstrap/dist/css/bootstrap.css';
import './footer.scss';
import facebooknew from '../images/facebook-log-type.png';
import twitternew from '../images/twitter-logotype.png';
import linkedinnew3 from '../images/linkedin-log-type.png';
import CookiesModal from '../cookies';

const Footer = () => {
	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    return (
        <div>
            <footer class="footer-main">
				<div class="top-header">
					<div class="container">
						<div class="row">
							<div class="col-sm-4"><div class="footer-logo">
							<NavLink to="/">	<img src="/images/logoFooter.png"/></NavLink>
								<p><b>Corporate Office: </b></p>
									<p>Gautam Buddha Nagar, Noida, UP 201301
									</p>
								</div>
							</div>
							<div class="col-sm-3">
							<h6>Useful Links</h6>
							<ul>
								<li> <NavLink to="/about">About Us</NavLink></li>
								<li> <NavLink to="/career">Career</NavLink></li>
								<li> <NavLink to="/wenidi-blogs">Blogs</NavLink></li>
								<li> <NavLink to="/contact">Contact Us</NavLink></li>
							</ul>
							</div>
							<div class="col-sm-5">
								<div className='startupIndiaInfo'>
									<div class="icon-media">
									<a href="https://www.facebook.com/Wenidi-100675375588795" target="_blank"><img src={facebooknew}/></a>
									<a href="https://twitter.com/WENIDI1" target="_blank"><img src="/images/twitter.png" alt="twitter"/></a>
									<a href="https://www.linkedin.com/company/wenidi-technologies/" target="_blank"><img src={linkedinnew3}/></a>
									<a href="https://www.instagram.com/wenidi_inc/" target="_blank"><img src="/images/instagram.png" alt="instagram"/></a>
									</div>
									<div className='startupIndia'><img src="/images/Logo1.png" alt="Startup India"/></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bottom-header">
					<div class="container">
						<div class="row">
							<div class="col-sm-8"><div class="privacy">© 2023 All rights reserved.</div></div>
							<div class="col-sm-4">
							    <div class="privacy" style={{textAlign:"right"}}><a><NavLink to="/terms">Terms and Conditions</NavLink></a> | <a><NavLink to="/privacy">Privacy Policy</NavLink></a></div>
							</div> 
						</div>
					</div>
				</div>
				<button
				className='scrollTopInfo'
				onClick={() => {
				window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
				}}
			    >
				<img src="/images/up.png" alt="Up"/>
			  </button>
	       </footer>
		   <CookiesModal/>
        </div>
      ); 
   }
  
  export default Footer;